<template>
  <v-card height="100%" width="100%">
    <v-card-text>
      <span @click="$router.push('/nests/' + nest._id)" :class="small ? 'title clickable' : 'display-2 clickable'">
        {{nest.name}} {{small && $route.params.id === nest._id ? ' (ausgewählt)' : ''}}
      </span>
      <v-tooltip v-if="small" v-model="showLastUpdate" bottom>
          <v-btn
            icon
            @mouseover="showLastUpdate = true"
            @mouseleave="showLastUpdate = false"
            @click="showLastUpdate = !showLastUpdate"
          >
            <v-icon
              small
              class="mb-1"
            >
              {{stateIcons.updatedAt.true}}
            </v-icon>
          </v-btn>
        <div class="caption">Aktualisiert {{$moment(nest.updatedAt).format('DD.MM.YYYY, HH:mm:ss')}} Uhr</div>
      </v-tooltip>
      <v-row v-if="!small">
        <v-col class="py-1 mt-5">
        Beschreibung: {{nest.info}}
        </v-col>
      </v-row>
      <v-row v-if="!small">
        <v-col class="py-1">
        Id: {{nest.path}}
        </v-col>
      </v-row>
      <v-row v-if="!small">
        <v-col class="py-1 mb-5">
          Aktualisiert: {{$moment(nest.updatedAt).format('DD.MM.YYYY, HH:mm:ss')}} Uhr
        </v-col>
      </v-row>
      <v-row v-if="!small && nest.startup && (computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('startup'))">
        <v-col class="py-1 mb-5">
          Version: {{nest.startup.value}}, aktualisiert am {{$moment(nest.startup.updatedAt).format('DD.MM.YYYY, HH:mm:ss')}}
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!small && nest.timeframeStart && nest.timeframeEnd && nest.openBelowNDegrees && nest.weatherColdestTemp">
          Aktiv von {{nest.timeframeStart.value}} Uhr bis {{nest.timeframeEnd.value}} Uhr bei Temperaturen unter {{nest.openBelowNDegrees.value}} °C.
          <br>
          Vorhersage ist {{(Math.round( nest.weatherColdestTemp.value * 10 ) / 10).toFixed(1)}} °C (aktualisiert {{$moment(nest.weatherColdestTemp.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr)
        </v-col>
      </v-row>
      <v-row
        v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('state')"
      >
        <v-col :class="small ? 'py-0' : ''">
          <v-alert
            dark
            :class="small ? 'px-1 py-1 my-2' : ''"
            dense
            tile
            :color="computedNestStateColor"
            text
          >
            <slot name="prepend">
              <v-icon
                :color="computedNestStateColor"
                class="mr-1"
                small
              >{{nest.state ? 'fas fa-info-circle' : 'fas fa-exclamation-circle'}}</v-icon>
            </slot>
            <template v-if="nest.state">
              {{stateTexts.state[nest.state.value]}}
            </template>
            <template v-else>
              Zustand derzeit unbekannt
            </template>
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        v-if="computedReadAny.attributes.length > 6 || computedReadAny.attributes.includes('*')"
      >
        <v-col :class="small ? 'my-0 py-0' : ''">
          <v-simple-table
            dense
          >
              <thead v-if="!small">
                <th>Eigenschaft</th>
                <th>Aktueller Wert / Letzter positiver Wert</th>
                <th v-if="!small">Zuletzt aktualisiert</th>
              </thead>
            <tbody>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('tempInside')">
                <td>Innentemperatur</td>
                <td v-if="nest.tempInside">{{(Math.round( nest.tempInside.value * 10 ) / 10).toFixed(1)}} °C</td>
                <td v-else>--.- °C</td>
                <td v-if="!small && nest.tempInside">{{$moment(nest.tempInside.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('tempOutside')">
                <td>Außentemperatur</td>
                <td v-if="nest.tempOutside">{{(Math.round( nest.tempOutside.value * 10 ) / 10).toFixed(1)}} °C</td>
                <td v-else>--.- °C</td>
                <td v-if="!small && nest.tempOutside">{{$moment(nest.tempOutside.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="!small && (computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('humidityInside'))">
                <td>Luftfeuchtigkeit innen</td>
                <td v-if="nest.humidityInside">{{(Math.round( nest.humidityInside.value * 10 ) / 10).toFixed(1)}} %</td>
                <td v-else>--.- %</td>
                <td v-if="!small && nest.humidityInside">{{$moment(nest.humidityInside.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="!small && (computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('humidityOutside'))">
                <td>Luftfeuchtigkeit außen</td>
                <td v-if="nest.humidityOutside">{{(Math.round( nest.humidityOutside.value * 10 ) / 10).toFixed(1)}} %</td>
                <td v-else>--.- %</td>
                <td v-if="!small && nest.humidityOutside">{{$moment(nest.humidityOutside.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('battery')">
                <td>Akkustand</td>
                <td v-if="nest.battery">{{(Math.round( scaleBattery(nest.battery.value) * 10 ) / 10).toFixed(1)}} % ({{(Math.round(nest.battery.value * 100 ) / 100).toFixed(2)}} V)</td>
                <td v-else>--.- %</td>
                <td v-if="!small && nest.battery">{{$moment(nest.battery.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('nukiLocked')">
                <td>Schlosszustand</td>
                <td v-if="nest.nukiLocked && nest.nukiLocked !== '' && nest.nukiLocked !== 'true' && nest.nukiLocked !== 'false'">{{stateTexts['nukiLocked'][intToHex(parseInt(nest.nukiLocked.value))]}}</td>
                <td v-else-if="nest.nukiLocked && nest.nukiLocked !== ''">{{stateTexts['nukiLocked'][nest.nukiLocked.value]}}</td>
                <td v-else>-</td>
                <td v-if="!small && nest.nukiLocked">{{$moment(nest.nukiLocked.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <template v-if="!small">
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('fan')">
                <td>Lüfter</td>
                <td v-if="nest.fan">{{(Math.round( scaleFan(nest.fan.value) * 10 ) / 10).toFixed(1)}} %</td>
                <td v-else>-</td>
                <td v-if="!small && nest.fan">{{$moment(nest.fan.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('lightInside')">
                <td>Licht innen</td>
                <td v-if="nest.lightInside && nest.lightInside.value">
                  {{$moment(nest.lightInside.value).isSame(nest.lightInside.updatedAt) ? 'An' : 'Aus / ' + $moment(nest.lightInside.value).format("DD.MM., HH:mm:ss") + ' Uhr'}}                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.lightInside">{{$moment(nest.lightInside.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('lightSignal')">
                <td>Lichtsignal</td>
                <td v-if="nest.lightSignal && nest.lightSignal.value">
                  {{$moment(nest.lightSignal.value).isSame(nest.lightSignal.updatedAt) ? 'An' : 'Aus / ' + $moment(nest.lightSignal.value).format("DD.MM., HH:mm:ss") + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.lightSignal">{{$moment(nest.lightSignal.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('motion')">
                <td>Bewegung Liegefläche</td>
                <td v-if="nest.motion && nest.motion.value">
                  {{$moment(nest.motion.value).isSame(nest.motion.updatedAt) ? 'Bewegung' : 'Zuletzt am ' + $moment(nest.motion.value).format('DD.MM., HH:mm:ss') + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.motion">{{$moment(nest.motion.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('motionAtDoor')">
                <td>Bewegung Eingang</td>
                <td v-if="nest.motionAtDoor && nest.motionAtDoor.value">
                  {{$moment(nest.motionAtDoor.value).isSame(nest.motionAtDoor.updatedAt) ? 'Bewegung' : 'Zuletzt am ' + $moment(nest.motionAtDoor.value).format('DD.MM., HH:mm') + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.motionAtDoor">{{$moment(nest.motionAtDoor.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('smoke')">
                <td>Rauchsensor</td>
                <td v-if="nest.smoke && nest.smoke.value">
                  {{$moment(nest.smoke.value).isSame(nest.smoke.updatedAt) ? 'Rauch' : 'Kein Rauch / ' + $moment(nest.smoke.value).format("DD.MM., HH:mm") + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.smoke">{{$moment(nest.smoke.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('doorOpen')">
                <td>Tür</td>
                <td v-if="nest.doorOpen && nest.doorOpen.value">
                  {{$moment(nest.doorOpen.value).isSame(nest.doorOpen.updatedAt) ? 'Offen' : 'Geschlossen / ' + $moment(nest.doorOpen.value).format("DD.MM., HH:mm") + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.doorOpen">{{$moment(nest.doorOpen.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('doorEmergencyOpen')">
                <td>Nottür</td>
                <td v-if="nest.doorEmergencyOpen && nest.doorEmergencyOpen.value">
                  {{$moment(nest.doorEmergencyOpen.value).isSame(nest.doorEmergencyOpen.updatedAt) ? 'Offen' : 'Geschlossen / ' + $moment(nest.doorEmergencyOpen.value).format("DD.MM., HH:mm") + ' Uhr'}}
                </td>
                <td v-else>-</td>
                <td v-if="!small && nest.doorEmergencyOpen">{{$moment(nest.doorEmergencyOpen.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              <!--
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('doorButton')">
                <td>Türknopf</td>
                <td v-if="nest.doorButton && nest.doorButton.value">{{$moment(nest.doorButton.value).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
                <td v-if="!small && nest.doorbutton">{{$moment(nest.doorButton.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              -->
              <tr v-if="computedReadAny.attributes.includes('*') || computedReadAny.attributes.includes('error')">
                <td>Fehler</td>
                <td v-if="nest.error && nest.error.value">{{nest.error.value}}</td>
                <td v-else>-</td>
                <td v-if="!small && nest.error">{{$moment(nest.error.updatedAt).format('DD.MM.YYYY, HH:mm')}} Uhr</td>
                <td v-else>-</td>
              </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-4" v-if="!small && (ac.can($store.state.auth.user.role).updateAny('nests').granted || ac.can($store.state.auth.user.role).updateAny('nestStates').granted)">
      <v-btn
        v-if="ac.can($store.state.auth.user.role).updateAny('nests').granted"
        @click="$router.push('/nests/editor/' + nest._id)"
        outlined
        class="mr-3"
      >
        Bearbeiten
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="ac.can($store.state.auth.user.role).updateAny('nestStates').granted">
        <v-btn
          outlined
          v-if="nest.smoke && nest.smoke.value === nest.smoke.updatedAt"
          @click="publish({ topic: 'control/' + nest.path + '/smokeReset', message: 'true' })"
        >
          Rauchmelder zurücksetzen
        </v-btn>
      </template>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    showLastUpdate: false
  }),
  props: [
    'nest',
    'small'
  ],
  methods: {
    ...mapActions('publisher', {
      publish: 'create'
    }),
    scaleBattery (num) {
      const inMin = 11.8
      const inMax = 12.5
      const outMin = 0
      const outMax = 100
      return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    },
    scaleFan (num) {
      if (num === false || num === 'false' || num === undefined) {
        return null
      }
      const inMin = 0
      const inMax = 256
      const outMin = -100
      const outMax = 100
      return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    },
    intToHex (integer) {
      let number = (integer).toString(16).toUpperCase()
      if ((number.length % 2) > 0) { number = '0' + number }
      return '0x' + number
    }
  },
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    computedNestStateColor () {
      if (!this.nest.state || this.nest.state.value.startsWith('STATE_CLOSED')) {
        return 'warning'
      } else if (this.nest.state.value.startsWith('STATE_OCC')) {
        return 'error'
      } else if (this.nest.state.value.startsWith('STATE_AVAILABLE')) {
        return 'success'
      } else {
        return 'info'
      }
    },
    computedReadAny () {
      return this.ac.can(this.$store.state.auth.user.role).readAny('nests')
    },
    computedNestPath () {
      return this.nest.path
    }
  }
}
</script>
