var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Übersicht")])])],1),_c('v-row',[_c('v-col',[_c('NestPreview',{attrs:{"nest":_vm.getNest(_vm.$route.params.id)}})],1)],1),(
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('tempInside') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('tempOutside') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('humidityInside') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('humidityOutside')
    )?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Klima"),_c('wbr'),_vm._v("Übersicht")])])],1),_c('v-row',[_c('v-col',[_c('NestStatistic',{attrs:{"nest":_vm.getNest(_vm.$route.params.id),"type":"klima"}})],1)],1)]:_vm._e(),(
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('battery') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('fan') ||
      _vm.ac.can(_vm.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('batteryTemp')
    )?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Technik"),_c('wbr'),_vm._v("Übersicht")])])],1),_c('v-row',[_c('v-col',[_c('NestStatistic',{attrs:{"nest":_vm.getNest(_vm.$route.params.id),"type":"tech"}})],1)],1)]:_vm._e(),(_vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nestControl').granted)?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Fern"),_c('wbr'),_vm._v("bedienung")])])],1),_c('v-row',[_c('v-col',[_c('Control',{attrs:{"nest":_vm.getNest(_vm.$route.params.id)}})],1)],1)]:_vm._e(),(_vm.$store.state.auth.user.role === 'administration')?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Debug")])])],1),_c('v-row',[_c('v-col',[_c('Debug',{attrs:{"nest":_vm.getNest(_vm.$route.params.id)}})],1)],1)]:_vm._e(),(_vm.$store.state.auth.user.role === 'administration')?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Update")])])],1),_c('v-row',[_c('v-col',[_c('Update',{attrs:{"nest":_vm.getNest(_vm.$route.params.id)}})],1)],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"display-2 grey--text font-weight-thin text-uppercase"},[_vm._v("Protokoll")])])],1),_c('v-row',[_c('v-col',[_c('NestDetail',{attrs:{"nest":_vm.getNest(_vm.$route.params.id)}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }