<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div style="max-width: 90%">
          <div class="title">Entriegeln</div>
          <div>
            Das Nest kann über nachfolgenden Button entriegelt werden. Dies kann einen kurzen Moment dauern und sollte akustisch leise zu hören sein.
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          class="my-4"
          outlined
          block
          @click="publish({ topic: 'control/' + computedNestPath + '/nukiLocked', message: 'false' })"
        >
          Nest entriegeln (Nuki)
        </v-btn>
        <v-btn
          v-if="$store.state.auth.user.role === 'administration'"
          class="my-4"
          outlined
          block
          @click="publish({ type: 'ttn', topic: 'control/' + computedNestPath + '/nukiLocked', message: 'false' })"
        >
          Nest entriegeln (Nuki) (TTN)
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div style="max-width: 90%">
          <div class="title">Bereitschaft aktivieren</div>
          <p>
            Das Nest kann über nachfolgenden Button wieder für die nächste Nacht aktiviert werden, wenn:
          </p>
          <ul>
            <li>Niemand mehr im Nest ist</li>
            <li>Alle Gegenstände aus dem Nest entfernt wurden</li>
            <li>Das Nest nicht gereinigt oder repariert werden muss</li>
          </ul>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          class="my-4"
          outlined
          block
          @click="publish({ topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_TEMP' })"
        >
          Nest aktivieren
        </v-btn>
        <v-btn
          v-if="$store.state.auth.user.role === 'administration'"
          class="my-4"
          outlined
          block
          @click="publish({ type: 'ttn', topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_TEMP' })"
        >
          Nest aktivieren (TTN)
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div style="max-width: 90%">
          <div class="title">Für Reinigung sperren</div>
          <p>
            Das Nest kann für eine Reinigung gesperrt werden, wenn:
          </p>
          <ul>
            <li>Niemand mehr im Nest ist</li>
            <li>Alle Gegenstände aus dem Nest entfernt wurden</li>
          </ul>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          class="my-4"
          outlined
          block
          @click="publish({ topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_CLEAN' })"
        >
          Nest muss gereinigt werden
        </v-btn>
        <v-btn
          v-if="$store.state.auth.user.role === 'administration'"
          class="my-4"
          outlined
          block
          @click="publish({ type: 'ttn', topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_CLEAN' })"
        >
          Nest muss gereinigt werden (TTN)
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div style="max-width: 90%">
          <div class="title">Für Reparatur sperren</div>
          <p>
            Das Nest kann für eine Reparatur gesperrt werden, wenn:
          </p>
          <ul>
            <li>Niemand mehr im Nest ist</li>
            <li>Alle Gegenstände aus dem Nest entfernt wurden</li>
          </ul>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          class="my-4"
          outlined
          block
          @click="publish({ topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_MAINTENANCE' })"
        >
          Nest muss repariert werden
        </v-btn>
        <v-btn
          v-if="$store.state.auth.user.role === 'administration'"
          class="my-4"
          outlined
          block
          @click="publish({ type: 'ttn', topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_MAINTENANCE' })"
        >
          Nest muss repariert werden (TTN)
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div style="max-width: 90%">
          <div class="title">Aus anderen Gründen sperren</div>
          <p>
            Das Nest kann gesperrt werden, wenn:
          </p>
          <ul>
            <li>Niemand mehr im Nest ist</li>
            <li>Alle Gegenstände aus dem Nest entfernt wurden</li>
          </ul>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          class="my-4"
          outlined
          block
          @click="publish({ topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_OTHER' })"
        >
          Nest sperren
        </v-btn>
        <v-btn
          v-if="$store.state.auth.user.role === 'administration'"
          class="my-4"
          outlined
          block
          @click="publish({ type: 'ttn', topic: 'control/' + computedNestPath + '/state', message: 'CLOSED_OTHER' })"
        >
          Nest sperren (TTN)
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  props: [
    'nest'
  ],
  data: () => ({
  }),
  computed: {
    computedNestPath () {
      return this.nest.path
    }
  },
  methods: {
    ...mapActions('publisher', {
      publish: 'create'
    })
  }
}
</script>

<style>
</style>
