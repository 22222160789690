var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-title',[_vm._v(" Job Editor ")]),_c('v-card-text',[(_vm.$route.params.id !== 'new')?[(_vm.computedJob && !_vm.computedJob.isActive)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dark":"","dense":"","tile":"","color":"warning","text":""}},[_vm._t("prepend",[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning","small":""}},[_vm._v(" fas fa-exclamation-triangle ")])]),_vm._v(" Die Aufgabe ist archiviert! ")],2)],1)],1):_vm._e()]:_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('nest')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('nest')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Nest auswählen","items":_vm.nests,"item-text":"name","item-value":"_id","rules":[_vm.rules.required]},model:{value:(_vm.nest),callback:function ($$v) {_vm.nest=$$v},expression:"nest"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('from')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('from')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Aufgabensteller auswählen","items":_vm.users.filter(function (obj) { return obj.isActive; }),"item-text":"fullName","item-value":"_id","rules":[_vm.rules.required]},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('type')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('type')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Aufgaben-Typ auswählen","items":[{ text: 'Aufsuchende Arbeit', value: 'outreaching'}, { text: 'Reinigung', value: 'cleaning'}, { text: 'Reparatur / Wartung', value: 'maintenance'} ],"rules":[_vm.rules.required]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('comment')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('comment')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Kommentar","rules":[_vm.rules.longText, _vm.rules.required]},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('followJobs')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('followJobs')
            )
          )
        )?[_c('v-row',[_c('v-col',[_vm._v(" Mögliche Folge-Aufgaben: ")])],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Reinigung","value":"cleaning"},model:{value:(_vm.followJobs),callback:function ($$v) {_vm.followJobs=$$v},expression:"followJobs"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Reparatur / Wartung","value":"maintenance"},model:{value:(_vm.followJobs),callback:function ($$v) {_vm.followJobs=$$v},expression:"followJobs"}})],1)],1)]:_vm._e()],2),_c('v-card-actions',{staticClass:"pa-4"},[(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('jobs').attributes.includes('isActive')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').granted &&
            (
              _vm.$store.state.auth.user._id === _vm.computedJob.from ||
              _vm.$store.state.auth.user.role === _vm.computedJob.type ||
              (_vm.computedJob.done && _vm.computedJob.done.by === _vm.$store.state.auth.user._id)
            ) &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('jobs').attributes.includes('isActive')
            )
          )
        )?[(_vm.$route.params.id !== 'new')?[(_vm.computedJob && _vm.computedJob.isActive)?_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.trashJob()}}},[_vm._v(" Archivieren ")]):_c('v-btn',{attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.untrashJob()}}},[_vm._v(" Aktivieren ")])]:_vm._e()]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":!_vm.formValid},on:{"click":function($event){return _vm.saveJob()}}},[_vm._v(" Speichern ")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }