<template>
  <v-container>
    <v-form v-model="loginFormValid">
      <v-card v-if="mode === 'login'">
        <v-card-title>
          Login
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                label="E-Mail"
                v-model="email"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required]"
                 @keydown.enter="email && password ? onLogin(): ''"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="loginError && loginError !== ''">
            <v-col class="error--text">
              {{loginError}}
              <v-btn
                class="ml-1"
                v-if="showResetPassword"
                small
                outlined
                color="error"
                @click="mode = 'reset'"
              >
                Passwort zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="pa-4"
        >
          <v-btn
            outlined
            :disabled="!loginFormValid"
            block
            @click="onLogin()"
          >
            Einloggen
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-form v-model="signupFormValid">
      <v-card v-if="mode === 'signup'">
        <v-card-title>
          Zugang beantragen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Vorname"
                v-model="firstName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Nachname"
                v-model="lastName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                label="E-Mail"
                v-model="email"
                :rules="[rules.required, rules.email]"
                :error-messages="emailError"
                @input="checkEmailError()"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required, rules.password]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="pa-4"
        >
          <v-btn
            outlined
            :disabled="!signupFormValid"
            block
            @click="onSignup()"
          >
            Zugang beantragen
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-form v-model="resetFormValid">
      <v-card v-if="mode === 'reset'">
        <v-card-title>
          Passwort zurücksetzen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                label="E-Mail"
                v-model="email"
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="pa-4"
        >
          <v-btn
            outlined
            :disabled="!resetFormValid"
            block
            @click="onReset()"
          >
            Passwort zurücksetzen
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-card v-if="mode === 'thanks'">
        <v-card-title>
          Vielen Dank
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Sie erhalten eine E-Mail sobald ihr Zugang verfügbar ist.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="mode === 'resetThanks'">
        <v-card-title>
          Vielen Dank
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Sie haben eine Email zum Zurücksetzen Ihres Passworts erhalten.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="backToLogin()"
                outlined
              >
                Zurück zur Anmeldung
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col>
          <v-btn
            v-if="mode === 'login'"
            x-small
            text
            @click="mode = 'signup'"
          >
            Zugang beantragen
          </v-btn>
          <v-btn
            v-if="mode === 'signup' || mode === 'thanks'"
            x-small
            text
            @click="mode = 'login'"
          >
            Anmelden
          </v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    email: '',
    password: '',
    loginFormValid: false,
    signupFormValid: false,
    resetFormValid: false,
    mode: 'login',
    firstName: '',
    lastName: '',
    emailError: [],
    loginError: '',
    showResetPassword: false
  }),
  mounted () {
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    ...mapActions('authManagement', {
      createAuth: 'create'
    }),
    ...mapActions('users', {
      createUser: 'create'
    }),
    backToLogin () {
      this.mode = 'login'
      this.password = undefined
      this.email = undefined
      this.emailError = []
      this.loginError = ''
    },
    onLogin () {
      this.authenticate({ strategy: 'local', email: this.email.trim(), password: this.password })
        .catch(error => {
          if (error.code === 401) {
            this.loginError = 'Passwort oder Email-Adresse ungültig.'
            this.showResetPassword = true
          }
          if (error.code === 400) {
            this.loginError = 'Der Nutzer wurde noch nicht freigeschaltet.'
          }
        })
    },
    onSignup () {
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password
      }
      this.createUser([map, {}])
        .then((result) => { this.mode = 'thanks' })
        .catch((error) => {
          if (error.errors && error.errors.email) {
            this.emailError = ['Es existiert bereits ein Zugang für diese Email-Adresse.']
          } else {
            //
          }
        })
    },
    onReset () {
      this.mode = 'resetThanks'
      this.createAuth({ action: 'sendResetPwd', value: { email: this.email } })
        .catch()
    },
    checkEmailError () {
      this.emailError = []
    }
  }
}
</script>
