<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <div class="display-2 grey--text font-weight-thin text-uppercase">Übersicht</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NestPreview
          :nest="getNest($route.params.id)"
        ></NestPreview>
      </v-col>
    </v-row>
    <template
      v-if="
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('tempInside') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('tempOutside') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('humidityInside') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('humidityOutside')
      "
    >
      <v-row>
        <v-col>
          <div class="display-2 grey--text font-weight-thin text-uppercase">Klima<wbr>Übersicht</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NestStatistic
            :nest="getNest($route.params.id)" type="klima"
          ></NestStatistic>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('battery') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('fan') ||
        ac.can($store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('batteryTemp')
      "
    >
      <v-row>
        <v-col>
          <div class="display-2 grey--text font-weight-thin text-uppercase">Technik<wbr>Übersicht</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NestStatistic
            :nest="getNest($route.params.id)" type="tech"
          ></NestStatistic>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="ac.can($store.state.auth.user.role).updateAny('nestControl').granted"
    >
      <v-row>
        <v-col>
          <div class="display-2 grey--text font-weight-thin text-uppercase">Fern<wbr>bedienung</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Control :nest="getNest($route.params.id)"></Control>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="$store.state.auth.user.role === 'administration'"
    >
      <v-row>
        <v-col>
          <div class="display-2 grey--text font-weight-thin text-uppercase">Debug</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Debug :nest="getNest($route.params.id)"></Debug>
        </v-col>
      </v-row>
    </template>
    <template
      v-if="$store.state.auth.user.role === 'administration'"
    >
      <v-row>
        <v-col>
          <div class="display-2 grey--text font-weight-thin text-uppercase">Update</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Update :nest="getNest($route.params.id)"></Update>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <div class="display-2 grey--text font-weight-thin text-uppercase">Protokoll</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NestDetail
          :nest="getNest($route.params.id)"
        ></NestDetail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import NestDetail from '@/components/NestDetail.vue'
import NestPreview from '@/components/NestPreview.vue'
import NestStatistic from '@/components/NestStatistic.vue'
import Debug from '@/components/Debug.vue'
import Control from '@/components/Control.vue'
import Update from '@/components/Update.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'nests',
  components: {
    NestDetail,
    NestPreview,
    NestStatistic,
    Debug,
    Control,
    Update
  },
  data: () => ({
  }),
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('nests', {
      getNest: 'get'
    })
  }
}
</script>
